import React, { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import Input from '../Input';
import { QrCodeDataContext } from '../../providers/QrCodeDataProvider';

import { ReactComponent as Motorcycle } from './images/motocykl.svg';
import { ReactComponent as PersonalCar } from './images/sam_osobowy.svg';
import { ReactComponent as ElectricCar } from './images/sam_elektr.svg';
import { ReactComponent as Truck } from './images/sam_ciezarowy.svg';
import { ReactComponent as TruckWithBodywork } from './images/ciezarowy_z_zabudowa.svg';
import { ReactComponent as Bus } from './images/autobus.svg';
import { ReactComponent as BuildingMachine } from './images/maszyna_budowlana_rolnicza.svg';
import { ReactComponent as AgriculturalTractor } from './images/ciagnik_rolniczy.svg';
import { ReactComponent as Sportcar } from './images/samochod_premium.svg';
import { ReactComponent as Semitrailer } from './images/naczepa.svg';
import { ReactComponent as ForkliftTruck } from './images/wozek_widlowy.svg';
import informationClause from '../../assets/files/Klauzula_informacyjna.pdf';
import valuationRules from '../../assets/files/Regulamin_wycena.pdf';

import lock from './images/lock.svg';
import { postForm } from '../../services';
import Loader from '../Loader';
import makesList from '../../constants/makesList';
import typeOfCarsCategory from '../../constants/typeOfCarsCategory';
import Select from '../Select';
import ReCAPTCHA from 'react-google-recaptcha';

const BUILDING_MACHINE_CATEGORY_ID = 1;
const regExpText = /^[A-Za-zęóąśłżźćńĘÓĄŚŁŻŹĆŃ -.]+$/u;
const regExpPhone = /^(([\+]?[0-9]{11})|([0-9]{9}))$/gm;
const regExpEmail = /\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/gi;
const regExpPostalCode = /^[0-9]{2}[-\s]*?[0-9]{3}$/;
const regExpNIP = /^[0-9]{10}$/;
const regExpVIN = /^[A-HJ-NPR-Za-hj-npr-z0-9]+$/; // W0L0TGF4835167621
const regExpRegNumber = /^([0-9a-zA-Z\s]+)$/; // GSP 85MW
const categories = [
  {
    id: 12,
    orderCode: 'DEK-WPM(S)',
    typesOfCategory: [typeOfCarsCategory.motorcycle],
    title: 'Motocykl',
    value: 'MOTOCYKL',
    price: '492,00',
    description: 'Wycena wartości motocykla',
    icon: <Motorcycle />
  },
  {
    id: 1,
    orderCode: 'DEK-WPO/T/D(S)',
    typesOfCategory: [typeOfCarsCategory.normalCars, typeOfCarsCategory.offRoadCars, typeOfCarsCategory.smallTrack],
    title: 'osobowy, terenowy, dostawczy <span>do 3,5 t</span>',
    value: 'SAMOCHÓD OSOBOWY',
    price: '615,00',
    description:
      'Wycena wartości samochodu osobowego, terenowego lub dostawczego o dopuszczalnej masie całkowitej do 3,5 t',
    icon: <PersonalCar />
  },
  {
    id: 8,
    orderCode: 'DEK-WPOE(S)',
    typesOfCategory: [typeOfCarsCategory.normalCars, typeOfCarsCategory.offRoadCars, typeOfCarsCategory.smallTrack],
    title: `elektryczny, hybryda <span>do 3,5 t</span>`,
    value: 'SAMOCHÓD ELEKTRYCZNY',
    price: '738,00',
    description: 'Wycena wartości samochodu elektrycznego/hybrydowego o dopuszczalnej masie całkowitej do 3,5 t',
    icon: <ElectricCar />
  },
  {
    id: 8,
    orderCode: 'DEK-WPS/P(S)',
    typesOfCategory: [''],
    title: 'sportowy, klasa premium',
    value: 'SAMOCHÓD SPORTOWY',
    price: '861,00',
    description:
      'Wycena wartości samochodu sportowego, klasy premium: Aston Martin, Audi A6/A7/A8/R8/TT/Q5/Q7/Q8/wersje ' +
      'S/wersje RS, Bentley, BMW 5/6/7/8/Z/wersje M/X5/X6/X7, Brabus, Chevrolet Corvette, Ferrari, Infiniti, ' +
      'Jaguar, Lamborghini, Lexus, Lotus, Maserati, Mercedes-Benz ' +
      '<span>E/CL/CLA/AMG/GLA/MAYBACH/S/R/G/GL/GLC/GLE/GLS/X/SL/SLK/wersje</span> AMG, Porsche, Rolls-Royce, VOLVO S90/V90',
    icon: <Sportcar />
  },
  {
    id: 5,
    orderCode: 'DEK-WPP(S)',
    typesOfCategory: [''],
    title: 'naczepa <span>powyżej 3,5 t</span>',
    value: 'NACZEPA',
    price: '676,50',
    description: 'Wycena wartości naczepy o dopuszczalnej masie całkowitej powyżej 3,5 t',
    icon: <Semitrailer />
  },
  {
    id: 3,
    orderCode: 'DEK-WPC(S)',
    typesOfCategory: [typeOfCarsCategory.bigTruck],
    title: 'ciężarowy',
    value: 'SAMOCHÓD CIĘŻAROWY',
    price: '799,50',
    description: 'Wycena wartości samochodu ciężarowego o dopuszczalnej masie całkowitej powyżej 3,5 t',
    icon: <Truck />
  },
  {
    id: 8,
    orderCode: 'DEK-WPCS(S)',
    typesOfCategory: [typeOfCarsCategory.bigTruck],
    title: 'ciężarowy z zabudową, naczepa z zabudową',
    value: 'SAMOCHÓD CIĘŻAROWY Z ZABUDOWĄ',
    price: '922,50',
    description: 'Wycena wartości samochodu ciężarowego z zabudową (np. wywrotka, betonomieszarka, hds, chłodnia)',
    icon: <TruckWithBodywork />
  },
  {
    id: 11,
    orderCode: 'DEK-WPA(S)',
    typesOfCategory: [typeOfCarsCategory.bus],
    title: 'autobus',
    value: 'AUTOBUS',
    price: '984,00',
    description: 'Wycena wartości autobusu',
    icon: <Bus />
  },
  {
    id: 8,
    orderCode: 'DEK-WPW(S)',
    typesOfCategory: [''],
    title: 'wózek widłowy',
    value: 'WÓZEK WIDŁOWY',
    price: '738,00',
    description: 'Wycena wartości wózka widłowego',
    icon: <ForkliftTruck />
  },
  {
    id: 6,
    orderCode: '',
    typesOfCategory: [''],
    title: 'maszyna',
    value: 'MASZYNA BUDOWLANA, ROLNICZA',
    icon: <BuildingMachine />,
    mainCategoryId: BUILDING_MACHINE_CATEGORY_ID
  },
  {
    id: 6,
    orderCode: 'DEK-WM-100',
    typesOfCategory: [''],
    title: 'maszyna o wartości',
    subtitle: 'do 100.000 zł (netto)',
    value: '',
    price: '1 107,00',
    description:
      'Wycena wartości pojedynczej maszyny (nie obejmuje linii technologicznych) o wartości do 100.000 zł (netto)',
    parentCategory: BUILDING_MACHINE_CATEGORY_ID,
    icon: <BuildingMachine />
  },
  {
    id: 6,
    orderCode: 'DEK-WM-200',
    typesOfCategory: [''],
    title: 'maszyna o wartości',
    subtitle: '100.001-200.000 zł (netto)',
    value: '',
    price: '1 230,00',
    description:
      'Wycena wartości pojedynczej maszyny (nie obejmuje linii technologicznych) o wartości od 100.001-200.000 zł (netto)',
    parentCategory: BUILDING_MACHINE_CATEGORY_ID,
    icon: <BuildingMachine />
  },
  {
    id: 6,
    orderCode: 'DEK-WM-300',
    typesOfCategory: [''],
    title: 'maszyna o wartości',
    subtitle: '200.001-300.000 zł (netto)',
    value: '',
    price: '1 599,00',
    description:
      'Wycena wartości pojedynczej maszyny (nie obejmuje linii technologicznych) o wartości od 200.001-300.000 zł (netto)',
    parentCategory: BUILDING_MACHINE_CATEGORY_ID,
    icon: <BuildingMachine />
  },
  {
    id: 6,
    orderCode: 'DEK-WM-500',
    typesOfCategory: [''],
    title: 'maszyna o wartości',
    subtitle: '300.001-500.000 zł (netto)',
    value: '',
    price: '1 845,00',
    description:
      'Wycena wartości pojedynczej maszyny (nie obejmuje linii technologicznych) o wartości od 300.001-500.000 zł (netto)',
    parentCategory: BUILDING_MACHINE_CATEGORY_ID,
    icon: <BuildingMachine />
  },
  {
    id: 6,
    orderCode: 'DEK-WM-750',
    typesOfCategory: [''],
    title: 'maszyna o wartości',
    subtitle: '500.001-750.000 zł (netto)',
    value: '',
    price: '2 214,00',
    description:
      'Wycena wartości pojedynczej maszyny (nie obejmuje linii technologicznych) o wartości od 500.001-750.000 zł (netto) ',
    parentCategory: BUILDING_MACHINE_CATEGORY_ID,
    icon: <BuildingMachine />
  },
  {
    id: 6,
    orderCode: 'DEK-WM-1000',
    typesOfCategory: [''],
    title: 'maszyna o wartości',
    subtitle: '750.001-1.000.000 zł (netto)',
    value: '',
    price: '2 706,00',
    description:
      'Wycena wartości pojedynczej maszyny (nie obejmuje linii technologicznych) o wartości od 750.001-1.000.000 zł (netto)',
    parentCategory: BUILDING_MACHINE_CATEGORY_ID,
    icon: <BuildingMachine />
  },
  {
    id: 6,
    orderCode: 'DEK-WM-1500',
    typesOfCategory: [''],
    title: 'maszyna o wartości',
    subtitle: '1.000.001-1.500.000 zł (netto)',
    value: '',
    price: '3 321,00',
    description:
      'Wycena wartości pojedynczej maszyny (nie obejmuje linii technologicznych) o wartości od 1.000.001-1.500.000 zł (netto)',
    parentCategory: BUILDING_MACHINE_CATEGORY_ID,
    icon: <BuildingMachine />
  },
  {
    id: 6,
    orderCode: 'DEK-WM-3000',
    typesOfCategory: [''],
    title: 'maszyna o wartości',
    subtitle: '1.500.001-3.000.000 zł (netto)',
    value: '',
    price: '4 305,00',
    description:
      'Wycena wartości pojedynczej (nie obejmuje linii technologicznych) maszyny o wartości od 1.500.001 - 3.000.000 zł (netto) - określenie wartości rynkowej (sprzedaż) na bazie oględzin, jeżeli będzie możliwe przeprowadzenie/obserwacja próby ruchowej/jazdy próbnej oraz określenie wartości metodą porównawczą, a jeżeli nie będzie to możliwe metodą kosztową',
    parentCategory: BUILDING_MACHINE_CATEGORY_ID,
    icon: <BuildingMachine />
  },
  {
    id: 6,
    orderCode: 'DEK-WM-5000',
    typesOfCategory: [''],
    title: 'maszyna o wartości',
    subtitle: '3.000.001-5.000.000 zł (netto)',
    value: '',
    price: '7 380,00',
    description:
      'Wycena wartości pojedynczej (nie obejmuje linii technologicznych) maszyny o wartości od 3.000.001 - 5.000.000 zł (netto) - określenie wartości rynkowej (sprzedaż) na bazie oględzin, jeżeli będzie możliwe przeprowadzenie/obserwacja próby ruchowej/jazdy próbnej oraz określenie wartości metodą porównawczą, a jeżeli nie będzie to możliwe metodą kosztową',
    parentCategory: BUILDING_MACHINE_CATEGORY_ID,
    icon: <BuildingMachine />
  },
  {
    id: 6,
    orderCode: 'DEK-WM-7500',
    typesOfCategory: [''],
    title: 'maszyna o wartości',
    subtitle: '5.000.001-7.500.000 zł (netto)',
    value: '',
    price: '9 840,00',
    description:
      'Wycena wartości pojedynczej (nie obejmuje linii technologicznych) maszyny o wartości od 5.000.001 - 7.500.000 zł (netto) - określenie wartości rynkowej (sprzedaż) na bazie oględzin, jeżeli będzie możliwe przeprowadzenie/obserwacja próby ruchowej/jazdy próbnej oraz określenie wartości metodą porównawczą, a jeżeli nie będzie to możliwe metodą kosztową',
    parentCategory: BUILDING_MACHINE_CATEGORY_ID,
    icon: <BuildingMachine />
  },
  {
    id: 6,
    orderCode: 'DEK-WM-10000',
    typesOfCategory: [''],
    title: 'maszyna o wartości',
    subtitle: '7.500.001-10.000.000 zł (netto)',
    value: '',
    price: '12 915,00',
    description:
      'Wycena wartości pojedynczej (nie obejmuje linii technologicznych) maszyny o wartości od 7.500.001 - 10.000.000 zł (netto) - określenie wartości rynkowej (sprzedaż) na bazie oględzin, jeżeli będzie możliwe przeprowadzenie/obserwacja próby ruchowej/jazdy próbnej oraz określenie wartości metodą porównawczą, a jeżeli nie będzie to możliwe metodą kosztową',
    parentCategory: BUILDING_MACHINE_CATEGORY_ID,
    icon: <BuildingMachine />
  },

  // {
  //   id: 8,
  //   orderCode: 'TEST-WEW',
  //   typesOfCategory: [''],
  //   title: 'ciągnik rolniczy',
  //   value: 'CIĄGNIK ROLNICZY',
  //   icon: <AgriculturalTractor />,
  // },
];
let captchaToken = '';
const FormSection = ({ forceResetForm, setForceResetForm, ifFormEnabled }) => {
  const [category, setCategory] = useState(null);
  const [parentCategory, setParentCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);
  const [acceptNewOrders, setAcceptNewOrders] = useState(true);
  const [linkToPayment, setLinkToPayment] = useState('');

  const recaptchaRef = useRef();
  const qrCodeContext = useContext(QrCodeDataContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();

  useEffect(() => {
    if (forceResetForm) {
      sendNewForm();
      setForceResetForm(false);
    }
  }, [forceResetForm]);

  useEffect(() => {
    if (qrCodeContext.data.VehicleCategory) {
      let actualCategory = categories.map((vehicle) => vehicle.value).indexOf(qrCodeContext.data.VehicleCategory);

      if (qrCodeContext.data.VehicleCategory === 'SAMOCHÓD OSOBOWY' && qrCodeContext.data.Fuel.includes('EE')) {
        actualCategory = categories.map((vehicle) => vehicle.value).indexOf('SAMOCHÓD ELEKTRYCZNY');
      }

      setCategory(actualCategory);

      let contactPerson = '';
      if (qrCodeContext.data.Name && qrCodeContext.data.Surname) {
        contactPerson = `${qrCodeContext.data.Name} ${qrCodeContext.data.Surname}`;
      }

      let ownerCompany = '';
      if (qrCodeContext.data.Name === null && qrCodeContext.data.Surname === null) {
        ownerCompany = qrCodeContext.data.Owner;
      }

      let dataFromQr = {
        ContactPerson: contactPerson,
        Name: qrCodeContext.data.Name,
        Surname: qrCodeContext.data.Surname,
        OwnerCompany: ownerCompany,
        VIN: qrCodeContext.data.Vin,
        Make: qrCodeContext.data.Make,
        RegNumber: qrCodeContext.data.RegistrationNumber,
        FirstRegDate: qrCodeContext.data.FirstRegistrationDate,
        InspectionAddressCity: qrCodeContext.data.City,
        InspectionAddressPostalCode: qrCodeContext.data.PostalCode,
        InspectionAddress: qrCodeContext.data.Adress
      };
      reset(dataFromQr);
    }
  }, [qrCodeContext]);

  const onSubmit = async (data) => {

    if (!captchaToken) {
      captchaToken = await recaptchaRef.current.executeAsync();
    }

    setLoading(true);
    data['VehicleCategoryId'] = categories[category].id;
    data['OrderCode'] = categories[category].orderCode;
    data['ContactPersonEmail'] = data['OwnerEmail'];

    await postForm(data)
      .then((res) => {
        setSuccess(true);
        setFail(false);
        setLinkToPayment(res.data.PaymentUrl);
      })
      .catch(() => {
        setSuccess(false);
        setFail(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const forceCaptchaChallenge = async () => {
    if (recaptchaRef) {
      captchaToken = await recaptchaRef.current.executeAsync();
    }
  };

  const captchaExpired = () => {
    captchaToken = '';
  };

  const resetCategory = () => {
    if (category && parentCategory) {
      setCategory(null);
      reset({});
      return;
    }

    setCategory(null);
    setParentCategory(null);
    reset({});
  };

  const handleCategory = (id) => {
    if (categories[id]?.mainCategoryId) {
      setParentCategory(id);
      return;
    }

    setCategory(id);
  };

  const filterMakesList = () => {
    let hintsListArray = [];
    makesList.forEach((make) => {
      if (categories[category].typesOfCategory.includes(make.kindId) && !hintsListArray.includes(make.makeName)) {
        hintsListArray.push(make.makeName);
      }
    });
    return hintsListArray;
  };

  const sendNewForm = () => {
    if (!success) {
      return;
    }
    setParentCategory(null);
    setCategory(null);
    reset({});
    setSuccess(false);
  };

  return (
    <div className="form-section" name="formSection">
      <Loader loading={loading} />
      <div className="container">
        {success ? (
          <div className="form-section__success-container">
            <h2 className="form-section__success-text">Dziękujemy za wysłanie zamówienia</h2>
            {linkToPayment && (
              <>
                <a
                  href={linkToPayment}
                  className="button button--primary button--width"
                  title="Przejdź do płatności"
                >
                  Przejdź do płatności
                </a>
                <div className="form-section__lock">
                  <img
                    className="form-section__lock__img"
                    src={lock}
                    alt="Bezpieczna płatność. Link do płatności otrzymasz mailem"
                  />
                  <div className="form-section__lock__text">Bezpieczna płatność.</div>
                </div>
            </>
            )}
          </div>
        ) : (
          <>
            <h2 className="form-section__header title">Zamów wycenę pojazdu lub maszyny</h2>
            {category !== null ? (
              <>
                {acceptNewOrders ? (
                  <>
                    <div className="form-section__back-arrow" onClick={resetCategory}></div>
                    <div className="form-section__form">
                      <div className="form-header">
                        <div className="form-header__wrapper">
                          <div className="form-header__category">
                            <div className="form-header__category__icon">{categories[category].icon}</div>
                            <div
                              className="form-header__category__title"
                              dangerouslySetInnerHTML={{ __html: categories[category].title }}
                            />
                            <p
                              className="form-header__category__subtitle"
                              dangerouslySetInnerHTML={{ __html: categories[category].subtitle }}
                            />
                          </div>
                          <div className="form-header__category__content">
                            <div className="form-header__title">
                              Oględziny oraz sporządzenie wyceny wartości (raport PDF)
                            </div>
                            <div className="form-header__text">
                              cena całkowita <span className="form-header__text__price">{categories[category].price}</span>{' '}
                              <span className="form-header__text__currency">zł</span> brutto
                            </div>
                          </div>
                        </div>
                        {categories[category].description && (
                          <div
                            className="form-header__description"
                            dangerouslySetInnerHTML={{ __html: categories[category].description }}
                          />
                        )}
                      </div>
                      {ifFormEnabled ? (
                        <form className="form" onSubmit={handleSubmit(onSubmit)}>
                          <div className="form__title">Dane kontaktowe:</div>
                          <div className="form__group">
                            <Input
                              errors={errors}
                              register={register}
                              label={'Imię'}
                              type={'text'}
                              inputName="Name"
                              validation={{
                                minLength: { value: 2, message: 'Minimalna liczba znaków to 2' },
                                required: { value: true, message: 'Wymagane' },
                                pattern: {
                                  value: regExpText,
                                  message: 'Podaj poprawne imię i nazwisko'
                                }
                              }}
                            />
                            <Input
                              errors={errors}
                              register={register}
                              label={'Nazwisko'}
                              type={'text'}
                              inputName="Surname"
                              validation={{
                                minLength: { value: 2, message: 'Minimalna liczba znaków to 2' },
                                required: { value: true, message: 'Wymagane' },
                                pattern: {
                                  value: regExpText,
                                  message: 'Podaj poprawne imię i nazwisko'
                                }
                              }}
                              // value={'Imię i nazwisko'}
                            />
                            <Input
                              errors={errors}
                              register={register}
                              label={'Nazwa firmy'}
                              type={'text'}
                              inputName="OwnerCompany"
                            />
                            <Input
                              errors={errors}
                              register={register}
                              label={'Telefon komórkowy'}
                              type={'number'}
                              inputName="ContactPhone"
                              validation={{
                                required: { value: true, message: 'Wymagane' },
                                pattern: { value: regExpPhone, message: 'Podaj poprawny telefon' }
                              }}
                              // value={'123456789'}
                            />
                            <Input
                              errors={errors}
                              register={register}
                              label={'nip'}
                              classNames={'uppercase-label'}
                              type={'number'}
                              inputName="OwnerNip"
                              validation={{
                                maxLength: { value: 10, message: 'Podaj poprawny NIP' },
                                minLength: { value: 10, message: 'Podaj poprawny NIP' },
                                pattern: { value: regExpNIP, message: 'Podaj poprawny NIP' }
                              }}
                              maxLength={10}
                              // value={'1234567891'}
                            />
                            <Input
                              errors={errors}
                              register={register}
                              label={'Email'}
                              type={'text'}
                              inputName="OwnerEmail"
                              validation={{
                                required: { value: true, message: 'Wymagane' },
                                pattern: { value: regExpEmail, message: 'Podaj poprawny e-mail' }
                              }}
                              // value={'adres@email.com'}
                            />
                          </div>
                          <p className="form__hint">
                            Dane niezbędne do wystawienia faktury VAT będzie można podać w formularzu płatności.
                          </p>
                          <div className="form__title">Dane obiektu:</div>
                          <div className="form__group">
                            {categories[category].parentCategory === BUILDING_MACHINE_CATEGORY_ID ? (
                              <>
                                <Input
                                  errors={errors}
                                  register={register}
                                  label={'Producent'}
                                  type={'text'}
                                  inputName="Make"
                                  validation={{
                                    minLength: {
                                      value: 2,
                                      message: 'Minimalna liczba znaków to 2'
                                    },
                                    required: { value: true, message: 'Wymagane' }
                                  }}
                                  // value={'Producent'}
                                />
                                <Input
                                  errors={errors}
                                  register={register}
                                  label={'Nr seryjny'}
                                  type={'text'}
                                  inputName="VIN"
                                  validation={{
                                    required: { value: true, message: 'Wymagane' },
                                    minLength: {
                                      value: 2,
                                      message: 'Minimalna liczba znaków to 2'
                                    },
                                    pattern: {
                                      value: regExpRegNumber,
                                      message: 'Podaj poprawny nr seryjny'
                                    }
                                  }}
                                />
                              </>
                            ) : (
                              <>
                                <Select
                                  errors={errors}
                                  register={register}
                                  label="Marka"
                                  inputName="Make"
                                  validation={{
                                    minLength: {
                                      value: 2,
                                      message: 'Minimalna liczba znaków to 2'
                                    },
                                    required: { value: true, message: 'Wymagane' }
                                  }}
                                  reset={reset}
                                  hintValues={filterMakesList()}
                                />
                                <Input
                                  errors={errors}
                                  register={register}
                                  label={'Nr rejestracyjny'}
                                  type={'text'}
                                  inputName="RegNumber"
                                  validation={{
                                    required: { value: true, message: 'Wymagane' },
                                    minLength: {
                                      value: 2,
                                      message: 'Minimalna liczba znaków to 2'
                                    },
                                    pattern: {
                                      value: regExpRegNumber,
                                      message: 'Podaj poprawny nr rejestracyjny'
                                    }
                                  }}
                                  // value={'GSP 85MW'}
                                />
                                <Input
                                  errors={errors}
                                  register={register}
                                  label={'vin'}
                                  classNames={'uppercase-label'}
                                  type={'text'}
                                  inputName="VIN"
                                  validation={{
                                    required: { value: true, message: 'Wymagane' },
                                    minLength: {
                                      value: 10,
                                      message: 'Minimalna liczba znaków to 10'
                                    },
                                    pattern: {
                                      value: regExpVIN,
                                      message: 'Podaj poprawny nr VIN'
                                    },
                                  }}
                                  // value={'W0L0TGF4835167621'}
                                />
                                <Input
                                  errors={errors}
                                  register={register}
                                  label={'Data pierwszej rejestracji'}
                                  type={'date'}
                                  inputName="FirstRegDate"
                                  validation={{
                                    minLength: {
                                      value: 2,
                                      message: 'Minimalna liczba znaków to 2'
                                    },
                                    required: { value: true, message: 'Wymagane' }
                                  }}
                                  // value={'2017-06-01'}
                                />
                              </>
                            )}
                          </div>
                          <div className="form__title">Adres oględzin:</div>
                          <div className="form__group">
                            <Input
                              errors={errors}
                              register={register}
                              label="Miasto"
                              type="text"
                              inputName="InspectionAddressCity"
                              validation={{
                                minLength: { value: 2, message: 'Minimalna liczba znaków to 2' },
                                required: { value: true, message: 'Wymagane' }
                              }}
                              // value={'Warszawa'}
                            />
                            <Input
                              errors={errors}
                              register={register}
                              label="Kod pocztowy"
                              type="text"
                              inputName="InspectionAddressPostalCode"
                              validation={{
                                required: { value: true, message: 'Wymagane' },
                                pattern: {
                                  value: regExpPostalCode,
                                  message: 'Podaj poprawny kod pocztowy'
                                }
                              }}
                              maxLength={6}
                              // value={'20-000'}
                            />
                            <Input
                              errors={errors}
                              register={register}
                              label="Ulica/nr"
                              type="text"
                              inputName="InspectionAddress"
                              validation={{
                                minLength: { value: 2, message: 'Minimalna liczba znaków to 2' },
                                required: { value: true, message: 'Wymagane' }
                              }}
                              // value={'Ulica numer'}
                            />
                          </div>
                          <div>
                            <Input
                              errors={errors}
                              register={register}
                              checkboxText={
                                `Oświadczam, że zapoznałam/em się z treścią <a target="_blank" href=${informationClause}>Klauzuli informacyjnej</a> dotyczącej przetwarzania danych osobowych`
                              }
                              type={'checkbox'}
                              inputName="ConsentToProcessingPersonalData"
                              validation={{
                                required: { value: true, message: 'Wymagane' }
                              }}
                            />
                            <Input
                              errors={errors}
                              register={register}
                              checkboxText={`Oświadczam, że zapoznałam/em się i akceptuję <a target="_blank" href=${valuationRules}>Regulamin usługi</a>`}
                              type={'checkbox'}
                              inputName="AcceptanceOfRegulations"
                              validation={{
                                required: { value: true, message: 'Wymagane' }
                              }}
                            />
                            <Input
                              errors={errors}
                              register={register}
                              checkboxText={'Oświadczam, że jestem Konsumentem w rozumieniu art. 22(1) kodeksu cywilnego, tj. osobą fizyczną dokonującą z Przedsiębiorcą czynności prawnej niezwiązanej bezpośrednio z jej działalnością gospodarczą lub zawodową'}
                              type={'checkbox'}
                              inputName="ConsentConsumer"
                              validation={{
                                required: false
                              }}
                            />
                            <Input
                              errors={errors}
                              register={register}
                              checkboxText={'Żądam niezwłocznego rozpoczęcia wykonywania Usługi, przed upływem terminu do odstąpienia od umowy. Akceptuję, że zgodnie z art. 38 pkt 1 ustawy o prawach konsumenta z dnia 30 maja 2014 r. (Dz.U. z 2014 r. poz. 827), w przypadku zgłoszenia tego żądania, po wykonaniu w pełni Usługi nie przysługuje mi prawo odstąpienia od umowy na podstawie tej ustawy (dotyczy Konsumentów)'}
                              type={'checkbox'}
                              inputName="StartServiceImmediatly"
                              validation={{
                                required: false
                              }}
                            />
                            <Input
                              errors={errors}
                              register={register}
                              checkboxText={'Wyrażam zgodę na otrzymywanie od DEKRA Polska sp. z o.o. informacji handlowo-marketingowych za pośrednictwem poczty e-mail'}
                              type={'checkbox'}
                              inputName="ConsentToEmailMarketingNotification"
                              validation={{
                                required: false
                              }}
                            />
                            <div className="recaptcha-container">
                              {/* // temporary added my private recaptcha v3 key */}
                              <ReCAPTCHA
                                sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                                onErrored={forceCaptchaChallenge}
                                onExpired={captchaExpired}
                                ref={recaptchaRef}
                                size="invisible"
                              />
                            </div>
                          </div>
                          <input
                            type="submit"
                            value="Zamów wycenę"
                            className={`button button--primary button--big form__button`}
                          />
                          {fail && (
                            <div className="form__fail-text">
                              Coś poszło nie tak, proszę skontaktować się z administratorem
                            </div>
                          )}
                        </form>
                      ) : (
                      <div className="form--disabled">
                        <p>Szanowni Państwo,</p>
                        <p>w porównaniu z analogicznym okresem w ubiegłym roku otrzymujemy obecnie znacznie więcej zleceń rzeczoznawczych.</p>
                        <p>Od początku roku aktywnie zwiększamy strukturę ekspertów DEKRA, jednak obecna i planowana ilość zleceń od stałych klientów powoduje, że nasza struktura rzeczoznawcza jest już obciążona w 100%.</p>
                        <p>W związku z powyższym, stawiając sobie wysokie standardy wykonywania naszych usług, w tym terminową realizację zleceń, postanowiliśmy do 07.06.2024 zawiesić przyjmowanie nowych zleceń.</p>
                        <p>Przepraszamy Państwa za powstałe niedogodności.</p>
                        <p>Zespół DEKRA Polska</p>
                      </div>
                      )}
                    </div>
                    <div className="form-section__back-arrow" onClick={resetCategory}></div>
                  </>
                ) : (
                  <div className='form-section__disabled-form'>
                    <p className='disabled-form__paragraph'>Szanowni Państwo,</p>
                    <p className='disabled-form__paragraph'>w porównaniu z analogicznym okresem w ubiegłym roku otrzymujemy obecnie ponad 50% więcej zleceń rzeczoznawczych.</p>
                    <p className='disabled-form__paragraph'>Od początku roku aktywnie zwiększamy strukturę ekspertów DEKRA, jednak  obecna i planowana ilość zleceń od stałych klientów powoduje, że nasza struktura rzeczoznawcza jest już obciążona w 100%.</p>
                    <p className='disabled-form__paragraph'>W związku z powyższym, stawiając sobie wysokie standardy wykonywania naszych usług, w tym terminową realizację zleceń, postanowiliśmy do odwołania zawiesić przyjmowanie nowych zleceń.</p>
                    <p className='disabled-form__paragraph'>Przepraszamy Państwa za powstałe niedogodności. </p>
                    <p className='disabled-form__paragraph'>Zespół DEKRA Polska</p>
                  </div>
                )}
              </>
            ) : (
              <>
                {parentCategory && <div className="form-section__back-arrow" onClick={resetCategory} />}
                <div className="form-section__categories">
                  <div>
                    {parentCategory
                      ? categories.map((category, index) => {
                          if (category?.parentCategory === categories[parentCategory]?.mainCategoryId) {
                            return (
                              <div className="category" onClick={() => handleCategory(index)} key={index}>
                                <div>
                                  <div className="category__icon">{category.icon}</div>
                                  <h3
                                    className="category__title"
                                    dangerouslySetInnerHTML={{ __html: category.title }}
                                  />
                                  {category?.subtitle && (
                                    <p
                                      className="category__subtitle"
                                      dangerouslySetInnerHTML={{ __html: category.subtitle }}
                                    />
                                  )}
                                </div>
                              </div>
                            );
                          }
                        })
                      : categories.map((category, index) => {
                          if (!category?.parentCategory) {
                            return (
                              <div className="category" onClick={() => handleCategory(index)} key={index}>
                                <div>
                                  <div className="category__icon">{category.icon}</div>
                                  <h3
                                    className="category__title"
                                    dangerouslySetInnerHTML={{ __html: category.title }}
                                  />
                                  {category?.subtitle && (
                                    <p
                                      className="category__subtitle"
                                      dangerouslySetInnerHTML={{ __html: category.subtitle }}
                                    />
                                  )}
                                </div>
                              </div>
                            );
                          }
                        })}
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default FormSection;
